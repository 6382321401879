<template>
  <div id="contact">
    <Menu page="contact"/>

    <section class="box box1">
      <div class="imgBox">
        <img src="../assets/images/contact_1.jpg" alt="contact1" v-if="screenWidth>640"/>
        <img src="../assets/images/contact_1_m.jpg" alt="contact1_m" v-if="screenWidth<=640"/>
      </div>
    </section>

    <section class="box box2">
      <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
        <span>{{ $t('contact.title.first') }}</span>
        <span>{{ $t('contact.title.second') }}</span>
      </div>

       <section class="infos">
        <div class="textBox">
          <p class="title">{{ $t('contact.box3.title') }}</p>
          <p class="text">
            <span>Restaurant KEI</span>
            <span>5 Rue Coq Heron 75001 PARIS</span>
            <span>+33 1 42 33 14 74</span>
            <span>reservationkei@gmail.com</span>
          </p>
        </div>

        <div class="textBox">
          <p class="title">{{ $t('contact.box4.title') }}</p>
          <p class="text">
            <span>{{ $t('contact.box4.p1') }}</span>
            <span>{{ $t('contact.box4.p2') }}</span>
            <span>{{ $t('contact.box4.p3') }}</span>
            <span>{{ $t('contact.box4.p4') }}</span>
            <span>{{ $t('contact.box4.p5') }}</span>
            <span>{{ $t('contact.box4.p6') }}</span>
            <span>{{ $t('contact.box4.p7') }}</span>
          </p>
        </div>


      </section>
      <section class="infos">
        <div class="textBox">
          <p class="title">{{ $t('contact.box1.title') }}</p>
          <p class="text">
            <span>- Sabrina Ubinana</span>
            <span>sabrina.ubinana@gmail.com</span>
          </p>

          <p class="title">{{ $t('contact.box1-bis.title') }}</p>
          <p class="text">
            <span>- Hélène Luzin</span>
            <span>helene@marquesetchefs.com</span>
          </p>
        </div>
        <div class="textBox">
          <p class="title">{{ $t('contact.box2.title') }}</p>
          <p class="text">
            <span>- Naonori Inazawa</span>
            <span>+33 6 11 03 13 56</span>
            <span>+81 90 74 15 26 42</span>
            <span>nao.restaurantkei@gmail.com</span>
          </p>
        </div>
        <div class="textBox">
          <p class="title">{{ $t('contact.box5.title') }}</p>
          <p class="text">
            <span><img class="m1" src="../assets/images/metro1.png"/>Louvre – Rivoli / Châtelet-les-Halles</span>
            <span><img class="m4" src="../assets/images/metro4.png"/>Etienne Marcel</span>
          </p>
          <p class="text">
            <span><img class="pk" src="../assets/images/parking.png"/>Parking Indigo Croix des petits Champs</span>
            <span>14 Rue Croix des petits Champs</span>
            <span>75001 Paris</span>
          </p>
          <p class="text">{{ $t('contact.box5.text') }}</p>
        </div>
      </section>
     
    </section>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer';
import Menu from '../components/Menu';

export default {
  name: 'contact',
  components: {
    Footer,
    Menu,
  },
  props: {},
  data() {
    return {
      screenWidth: screen.width,
    };
  },
  mounted() {
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.box1 {
  margin: 0;
}

.text {
  color: #000;
  white-space: break-spaces;
}

.box {
  overflow: hidden;
  position: relative;
  height: auto;
  display: block;
  align-items: center
}

p {
  margin: 25px 0;
}

.textBox {
  padding-left: unset;
  margin: 0 30px
}

.infos .textBox:first-child {
  margin-left: 0;
}

.box.box1 {
  height: auto;
}

.imgBox {
  height: auto;
}

hr {
  height: 1px;
  width: 500px;
  background: #660032;
  margin: 200px auto;
}

.box1 .imgBox {
  width: 100%;
}


.box1 .imgBox img {
  width: 100%;
  height: auto;
}

.box2 {
  padding: 6vw;
}

.titleText {
  color: #000;
}

.menu {
  text-align: center;
}

.infos {
  display: flex;
  color: #000;
  text-align: left;
}

.titleText::after {
  background: #000;
}

.titleText + .infos {
  margin-top: 200px;
}

.text span {
  display: block;
  margin-top: 8px;
}

.title {
  font-size: 30px;
  font-weight: 700;
}

.m1, .m4{
  width: 50px;
  position : relative;
  top:4px;
}

.pk{
  width:25px;
  top:6px;
}




@media (max-width: 910px) {
  .titleText::after {
    left: 41vw;
  }

  .textBox, .titleText, .bigText {
    text-align: center;
  }

  .infos {
    flex-direction: column;
    align-items: center;
  }

  .titleText + .infos {
    margin-top: 80px;
  }

  .box2 {
    padding: 2%;
    width: 96%;
  }

  .textBox {
    margin: 0;
  }
}

</style>