import Vue from "vue";
import VueRouter from "vue-router";
import Home from './pages/Home.vue'
import Universe from './pages/Universe.vue'
import Experience from './pages/Experience.vue'
import Harmony from './pages/Harmony.vue'
import Degustation from './pages/Degustation.vue'
import Contact from './pages/Contact.vue'
import Sensibility from "@/pages/Sensibility";
import Admin from "@/pages/Admin";
import Login from "@/pages/Login";
import Booking from "@/pages/Booking";
import Career from "@/pages/Career";
import LegalMention from "@/pages/LegalMention";
import Gift from "@/pages/Gift";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/universe',
        name: 'universe',
        component: Universe
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin
    },
    {
        path: '/keiadministration-pejgltmrnsz-wxhgkem',
        name: 'login',
        component: Login
    },
    {
        path: '/experience',
        name: 'experience',
        component: Experience
    },
    {
        path: '/harmony',
        name: 'harmony',
        component: Harmony
    },
    {
        path: '/degustation',
        name: 'degustation ',
        component: Degustation
    },
    {
        path: '/sensibility',
        name: 'Sensibility',
        component: Sensibility
    },
    {
        path: '/career',
        name: 'Career',
        component: Career
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },
    {
        path: '/booking',
        name: 'booking',
        component: Booking
    },
    {
        path: '/gift',
        name: 'gift',
        component: Gift
    },
    {
        path: '/legal-mention',
        name: 'legalMention',
        component: LegalMention
    },
];

export default new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
});



