<template>
  <div id="admin">
    <Menu page="admin"/>
   <section >
            <label for="french">Version francaise (obligatoire)</label>
            <textarea name="french" v-model="french"></textarea>
            <label for="english">Version Anglaise (obligatoire)</label>
            <textarea v-model="english"></textarea>
            <label for="japanese">Version Japonaise (obligatoire)</label>
            <textarea v-model="japanese"></textarea>

            <div class="button" @click="publishNews">Envoyer</div>
            <div class="button newsButton" @click="toggleNews" v-if="this.$store.getters.getNews">{{this.$store.getters.getNews.showNews? 'Cacher la newsletter' : 'Afficher la newsLetter'}}</div>
            <p class="text" v-if="showMessage">{{ this.message}}</p>

        </section>
  </div>
</template>

<script>
import Menu from '../components/Menu';
import {store} from '../store/store';
import {mapActions} from 'vuex'

import router from '../router';

export default {
  name: 'admin',
  components: {
    Menu,
  },
  props: {},
  data() {
    return {
      screenWidth: screen.width,
      french:store.getters.getNews? store.getters.getNews.french : "",
      english:store.getters.getNews? store.getters.getNews.english : "",
      japanese:store.getters.getNews? store.getters.getNews.japanese : "",
      showMessage: false,
      message:"Vous devez remplir tout les champs ci-dessous",
      user: store.getters.getUser,
    };
  },
  mounted() {
    this.$store.subscribe( (mutation, state) => {
      if (mutation.type==="setUser" ) {
        this.user=state.user;
        !state.user && router.push('/');
      }
    })

    if(!this.user){
      router.push('/')
    }
  },
  methods:{
     ...mapActions([
      'addNews',
      'toggleShowNews',
    ]),
    toggleNews(){
      this.toggleShowNews(!store.getters.getNews.showNews);
    },
    displayMessage(message){
      this.message=message;
      this.showMessage = true;
      window.setTimeout(() => {
        this.showMessage = false;
      }, 3000)
    },
    async publishNews(){
      const {french, english, japanese}=this;
      if(!french||!english|| !japanese){
        this.displayMessage("Vous devez remplir tout les champs ci-dessus");
        return;
      }
      try {
        await this.addNews({french, english, japanese});
        this.displayMessage("la news a bien été sauvegarder");
      } catch (error) {
        console.log(error);
        this.displayMessage("l'envoi des données à échoué");
      }
    
    }
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#admin{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15vh;
  width:100%;
  position: relative;
}

.text{
  text-align: center;
}

section {
  margin: 0 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
}

section textarea{
  display: block;
  margin :0px 0 25px;  
  width: 90%;
  height: 150px;
}
.newsButton{
  position: fixed;
  bottom: 2vh;
  right:2vw;
}

.button.newsButton{
  font-size: 14px;
  padding: 5px 15px;
  line-height:20px;
}

.button {
  margin: 40px auto 0;
  display: inline-block;
  padding: 10px 40px;
  height: 19px;
  border: 2px solid #fff;
  color: #fff;
  background: #660032;
  cursor: pointer;
  font-size: 18px;
  font-family: inherit;
}


</style>