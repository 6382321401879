<template>
  <main :class="show ? 'active' : 'not-active'" id="bookingRedirection">
    <div class="bookingContainer">
      <div
          class="btn"
          @click="onCloseClick">
        <span></span>
        <span></span>
      </div>

      <section v-if="mode!=='news'">
        <p class="infos">{{ $t('menu.overlayText') }}</p>

        <div class="text">
          <div>
            <a href="https://bookings.zenchef.com/results?rid=365417&pid=1001" target="_blank" class="redirectLink">{{ $t('menu.booking1') }}</a>
          </div>
          <div>
            <a href="https://restaurant-kei.secretbox.fr/" target="_blank" class="redirectLink">{{ $t('menu.booking2') }}</a>
          </div>
        </div>
      </section>

      <section v-if="mode==='news' && news">
        <LanguagePicker mode="alternate"/>
        <p class="news">{{ news }}</p>
      </section>
     
    </div>
  </main>
</template>

<script>
import {store} from '../store/store';
import LanguagePicker from "@/components/LanguagePicker";


export default {
  name: 'BookingOverlay',
  props: {
    show: Boolean,
    mode: String
  },
  components: {LanguagePicker },
  data() {
    return {
      news : this.$i18n.locale==="fr" && store.getters.getNews
          ? store.getters.getNews.french
          : this.$i18n.locale==="en" && store.getters.getNews ? store.getters.getNews.english
          : this.$i18n.locale==="ja" && store.getters.getNews ?store.getters.getNews.japanese:undefined,
    };
  },
  mounted() {

  },
  updated(){
    this.news = this.$i18n.locale==="fr" && store.getters.getNews
          ? store.getters.getNews.french
          : this.$i18n.locale==="en" && store.getters.getNews ? store.getters.getNews.english
          : this.$i18n.locale==="ja" && store.getters.getNews ?store.getters.getNews.japanese:undefined;
  },
  methods: {
    onCloseClick() {
      this.$emit('clicked', false)
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

.text {
  text-align: center;
}

.redirectLink {
  position: relative;
  margin: 40px 0;
  display: inline-block;
}

.redirectLink:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(1);
  height: 2px;
  bottom: -6px;
  left: 0;
  background: #fff;
  transform-origin: bottom right;
  transition: transform 0.6s ease-out;
}


.redirectLink:hover:after {
  transform: scaleX(0.2);
  transform-origin: bottom right;
}

#bookingRedirection {
  position: fixed;
  left: 0;
  top: 0;
  padding: 2%;
  width: 96%;
  height: 100%;
  background: rgba(0, 0, 0, .89);
  color: #fff;
  transition: all .5s;
  z-index: 1000;
  transform: translateX(100%);
}

#bookingRedirection.active {
  transform: translateX(0%);
}

.bookingContainer {
  position: relative;
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}


.bookingContainer .btn {
  position: fixed;
  right: 105px;
  top: 84px;
  z-index: 100000;
  width: 60px;
  height: 60px;
}

.bookingContainer .btn span {
  width: 50px;
}

.bookingContainer .btn span:nth-child(1) {
  top: 22px;
  transform: rotate(45deg);
}

.bookingContainer .btn span:nth-child(2) {
  bottom: -22px;
  transform: rotate(135deg);
}

.language{
  flex-wrap: nowrap;
  position: fixed;
  left: 105px;
  top: 84px;
  font-size: 20px;
}

.news {
  font-size: 26px;
  text-transform: none;
  text-align: center;
}

@media (max-width: 640px) {
  .infos {
    font-size: 10px;
  }

  .news {
    font-size: 18px;
  }

  .bookingContainer .btn {
    right: 0;
    top: 15px;
  }

  .language{
    left: 10px;
    top: 15px;
  }

  #bookingRedirection {
    top: 50%;
    transform: translateY(-50%) translateX(100%);
  }

  #bookingRedirection.active {
    transform: translateY(-50%) translateX(0%)
  }
}
</style>
