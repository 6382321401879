<template>
  <div id="sensibility">
    <Menu page="sensibility"/>
    <div class="box box1">
      <div class="imgBox">
        <img src="../assets/images/sensibility_1.jpg" alt="sensibility1" v-if="screenWidth>640"/>
        <img src="../assets/images/sensibility_1_m.jpg" alt="sensibility1m" v-if="screenWidth<=640"/>
      </div>
    </div>
    <section class="box1 textBox">
      <p :class="$root._i18n._vm.locale ==='ja' ? 'bigText textHeader ja' : 'bigText textHeader'">{{ $t('sensibility.box1.text') }}</p>
    </section>
    <section class="box tripleColumns">
      <div class="tripleColumnsContainer">
        <div class="imgComposition">
          <img src="../assets/images/sensibility_2-1.jpg"
               alt="sensibility2-1"
               data-aos="fade-down"
               data-aos-easing="linear"
               data-aos-duration="1000"/>
          <img src="../assets/images/sensibility_2-2.jpg"
               alt="sensibility2-2"
               data-aos="fade-down"
               data-aos-easing="linear"
               data-aos-duration="1000"
               data-aos-delay="600"/>
        </div>
        <div class="textBox" data-aos="zoom-out-left" data-aos-duration="1000">
          <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
            <span>{{ $t('sensibility.box2.title.first') }}</span>
            <span>{{ $t('sensibility.box2.title.second') }}</span>
          </div>
          <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('sensibility.box2.text') }}</p>
        </div>
      </div>
    </section>
    <section class="box box3">
      <div class="imgBox">
        <img src="../assets/images/sensibility_3.jpg"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"
             alt="sensibility_3"/>
      </div>
      <div class="textBox"
           data-aos="fade-up"
           data-aos-easing="linear"
           data-aos-duration="1000"
           data-aos-delay="600">
        <p :class="$root._i18n._vm.locale ==='ja' ? 'bigText ja' : 'bigText'">{{ $t('sensibility.box3.text') }} </p>
        <p class="author">{{ $t('sensibility.box3.author') }}</p>
      </div>

    </section>
    <Offer/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import Offer from "@/components/Offer";

export default {
  name: 'sensibility',
  components: {
    Offer,
    Footer,
    Menu,
  },
  props: {},
  data() {
    return {
      screenWidth: screen.width,
    };
  },
  mounted() {
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');


.box1 {
  margin: 0;
}

.bigText.ja {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

p {
  margin: 25px 0;
}

.box.box1 {
  height: auto;
}

.imgBox {
  height: auto;
}

.box1 .imgBox {
  width: 100%;
}

.box1 .imgBox img, .box3 .imgBox img {
  width: 100%;
  height: auto;
}

.box1.textBox {
  text-align: center;
  padding: 0;
  margin: 0 auto;
}

.box2 .textBox {
  width: 50%;
}

.simpleBoxImgRight .imgBox {
  width: 60%;
}

.simpleBoxImgRight .textBox {
  padding: 6%;
  width: 40%;
}

.box3 {
  justify-content: flex-end;
  margin: 200px 0;
}


.box3 .imgBox {
  width: 48vw;
}

.box3 .imgBox {
  text-align: right;
  position: relative;
}


.box3 .textBox {
  position: absolute;
  text-align: right;
  padding: 0;
  left: 35vw;
}

.offer.textBox {
  width: 100%;
}

.tripleColumns .textBox {
  position: absolute;
  top: 73vh;
  left: 56vw;
}

.tripleColumns .textBox p {
  margin-top: 98px;
}

.box.tripleColumns {
  height: auto;
}

.tripleColumns img:nth-child(2) {
  top: 0;
  left: 52vw;
  position: absolute;
  width: 32vw;
}

.tripleColumns img:first-child {
  top: 12vh;
  left: 0;
  width: 44vw;
}

.tripleColumns .imgComposition {
  justify-content: flex-start;
  width: 100%;
}

.box3 .textBox {
  color: #646470;
  left: 32vw;
}

@media (max-width: 1610px) {
  .tripleColumns .textBox {
    top: 65vh;
    left: 50vw;
  }
}

@media (max-width: 1400px) {
  .box3, .tripleColumns .tripleColumnsContainer {
    align-items: center;
    flex-direction: column;
    overflow: visible;
  }


  .tripleColumns {
    height: auto;
  }

  .tripleColumns .titleText, .tripleColumns .text {
    text-align: center;
  }

  .tripleColumns .textBox {
    position: relative;
    top: unset;
    left: unset;
    margin: 230px 0 0;
    text-align: center;
  }

  .titleText.ja::after {
    top: 101px;
  }

  .tripleColumns .textBox {
    padding-left: 0;
  }

  .tripleColumns .titleText::after {
    left: 45%;
    transform: rotate(-45deg) translateX(-50%);
  }

  .tripleColumns .tripleColumnsContainer {
    align-items: center;
  }

  .tripleColumns {
    margin: 70px 0 50px;
    overflow: hidden;
  }

  .box3 {
    justify-content: flex-end;
    margin: 0;
  }

  .box3 .textBox {
    position: relative;
    left: unset;
    width: 85%;
    margin-bottom: 0;
    padding: 0;
  }

  .box3 .imgBox {
    width: 82vw;
  }

  .box3 .bigText {
    text-align: center;
  }
  .author{
    text-align: center;
  }
}

@media (max-width: 640px) {
  .titleText.ja::after {
    top: 75px;
  }


  .tripleColumns img:nth-child(2) {
    right: 3vw;
    left: unset;
  }

  .tripleColumns img:first-child {
    left: 0;
    width: 52%;
  }


  .tripleColumns .textBox {
    position: relative;
    top: unset;
    left: unset;
    margin: 130px 0 0;
  }

  .box1.textBox {
    padding: 0;
  }

  .box3 {
    margin: 0;
  }

  .box3 .bigText {
    margin-top: 70px;
  }

}

</style>