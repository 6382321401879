<template>
  <div id="offer" class="textBox offer"
       data-aos="zoom-in-down"
       data-aos-easing="linear"
       data-aos-duration="800">
    <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'" :style="{color:textColor}">
      <span>{{ $t('home.offerBox.title.first') }}</span>
    </div>
    <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'" :style="{color:textColor}">{{ $t('home.offerBox.text') }}</p>
    <a href="https://restaurant-kei.secretbox.fr/" target="_blank">
      <button class="cta">
        {{ $t('home.offerBox.cta') }}
      </button>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Offer',
  props: {
    textColor: String
  },
  data() {
    return {};
  },
  mounted() {

  },
  methods: {},
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.offer.textBox {
  left: unset;
  text-align: center;
  padding: 0;
  position: relative;
  margin: 160px 0 200px;
  width: 100%;
}


.offer .titleText::after {
  top: 110px;
  left: 48vw;
}

.offer .cta {
  margin-top: 20px;
}

#world {
  margin: 0;
  padding: 0;
  position: absolute;
  display: block;
  touch-action: none;
}

@media (max-width: 640px) {

  .offer.textBox {
    margin: 100px 0 130px;
  }

  .offer .titleText::after {
    left: 44vw;
    top: 93px;
  }
}


</style>
