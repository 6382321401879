<template>
  <div id="universe">
    <Menu page="universe"/>
    <section class="box box1">
      <div class="imgBox">
        <img src="../assets/images/universe1.jpg" alt="universe1" v-if="screenWidth>640"/>
        <img src="../assets/images/universe_1_m.jpg" alt="universe1m" v-if="screenWidth<=640"/>
      </div>
    </section>
    <section class="box1 textBox">
      <p :class="$root._i18n._vm.locale ==='ja' ? 'bigText textHeader ja' : 'bigText textHeader'">{{ $t('universe.box1.text') }}</p>
    </section>
    <section class="box box2 simpleBoxImgRight">
      <div class="imgBox" v-if="screenWidth<=640">
        <img src="../assets/images/universe2-1.jpg"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"
             alt="universe2-1m"/>
      </div>
      <div class="textBox"
           data-aos="fade-up"
           data-aos-easing="linear"
           data-aos-duration="1000"
           data-aos-delay="600">
        <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
          <span>{{ $t('universe.box2.title.first') }}</span>
          <span>{{ $t('universe.box2.title.second') }}</span>
        </div>
        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('universe.box2.text.part1') }}</p>
        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('universe.box2.text.part2') }}</p>
        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('universe.box2.text.part3') }}</p>
        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('universe.box2.text.part4') }}</p>
      </div>
      <div class="imgBox" v-if="screenWidth>640">
        <img src="../assets/images/universe2-1.jpg"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"
             alt="universe2-1"/>
      </div>
    </section>

    <section class="box box3">
      <div class="imgBox">
        <img src="../assets/images/universe3-1.jpg"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"
             alt="universe3-1"/>
      </div>
      <div class="textBox"
           data-aos="fade-up"
           data-aos-easing="linear"
           data-aos-duration="1000"
           data-aos-delay="600">
        <p :class="$root._i18n._vm.locale ==='ja' ? 'bigText ja' : 'bigText'">{{ $t('universe.box3.text') }} <span class="author">{{ $t('universe.box3.author') }}</span></p>
      </div>

    </section>

    <section class="box degustation tripleColumns">
      <div class="tripleColumnsContainer">
        <div class="imgComposition">
          <img src="../assets/images/universe4-1.jpg"
               data-aos="fade-down"
               data-aos-easing="linear"
               data-aos-duration="1000"
               data-aos-delay="600"
               alt="universe4-1"/>
          <img src="../assets/images/universe4-2.jpg"
               data-aos="fade-down"
               data-aos-easing="linear"
               data-aos-duration="1000"
               alt="universe4-2"/>
        </div>
      </div>
    </section>
    <Offer/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import Offer from "@/components/Offer";

export default {
  name: 'universe',
  components: {
    Offer,
    Footer,
    Menu,
  },
  props: {},
  data() {
    return {
      screenWidth: screen.width
    };
  },
  mounted() {
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.box1 {
  margin: 0;
}

.bigText.ja{
  font-style: italic;
}

.box2 .text.ja{
    white-space: break-spaces;
}

.bold {
  font-weight: bold;
}

p {
  margin: 25px 0;
}

.box.box1 {
  height: auto;
}

.imgBox {
  height: auto;
}

.box1 .imgBox {
  width: 100%;
}


.box1 .imgBox img, .box3 .imgBox img {
  width: 100%;
  height: auto;
}

.box1.textBox {
  text-align: center;
  padding: 0;
  margin: 0 auto;
}

.titleText + p {
  margin-top: 96px;
}

.box2 {
  height: auto;
}

.box2 .textBox {
  width: 50%;
}

.simpleBoxImgRight .imgBox {
  height: 100%;
}

.simpleBoxImgRight .textBox {
  padding: 6%;
  width: 40%;
}

.box3 {
  margin: 200px 0;
}

.box3 .imgBox {
  width: 48vw;
}

.box3 .textBox {
  position: absolute;
  padding: 0;
  left: 40vw;
}

.offer.textBox {
  width: 100%;
}

@media (max-width: 640px) {
  .titleText.ja::after{
    top: 88px;
  }
  .box3 .textBox {
    position: relative;
    left: unset;
  }

  .imgBox img, .simpleBoxImgRight .imgBox {
    height: auto;
    width: 82vw;
  }

  .box3 .imgBox {
    width: 82vw;
    margin-bottom: 50px;
  }

  .simpleBoxImgRight .textBox {
    width: 85%;
    padding: 0;
    margin-top: 60px;
  }

  .titleText::after {
    left: 33vw;
  }

  .box2 {
    margin: 70px 0 0;
  }

  .box3 {
    margin: 0;
  }

  .offer.textBox {
    margin: 140px 0 130px;
  }

  span.author {
    display: block;
    margin-top: 15px;
  }

  .degustation {
    margin-top: 0;
  }


}

</style>