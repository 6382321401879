import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '../router';
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    key:"restaurant-kei",
    storage: window.localStorage
})

Vue.use(Vuex);
export const api_url = "https://www.restaurant-kei-api.restaurant-kei.fr/";
// export const api_url = "http://localhost:3000";

export const store = new Vuex.Store({
    state: {
        user: null,
        token:null,
        news: null,
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
         setToken(state, token) {
            state.token = token;
        },
          setNews(state, news) {
            state.news = news;
        },
    },
    getters: {
        getMedias: state => state.medias,
        getUser: state => state.user,
        getToken: state => state.token,
        getNews: state => state.news,
    },
    actions: {
        login(context, data) {
            return axios
                .post(`${api_url}/login`, { email:data.email, password: data.password})
                .then(res => {
                    if (res.status === 200) {
                        this.commit('setUser', res.data.user);
                        this.commit('setToken', res.data.token);
                        router.push('/admin')
                    } 
                })
        },
        registerEmail(context, data) {
            axios.post(`${api_url}/file`, { email: this.value }, {
            headers: {
              'Access-Control-Allow-Origin': 'https://www.restaurant-kei-api.restaurant-kei.fr',
            //   'Access-Control-Allow-Origin': '*',
            }
          });
        },
        getNews(context) {
            return axios
                .get(`${api_url}/news`)
                .then(res => {
                    if (res.status=== 200) {
                        this.commit('setNews', res.data.newsMessage);
                    }
                })
                .catch(err => console.log(err))
        },
        toggleShowNews(context, showNews) {
             const config = {
                headers: {
                    'Authorization': `${this.getters.getToken}`
                }
            };
            return axios
                .patch(`${api_url}/news`,{showNews} , config)
                .then(res => {
                    if (res.status=== 200) {
                        this.commit('setNews', res.data.newsMessage);
                    }
                })
                .catch(err => console.log(err))
        },
        addNews(context, data) {
            const config = {
                headers: {
                    'Authorization': `${this.getters.getToken}`
                }
            };
            return axios
                .post(`${api_url}/news`, { french: data.french, english: data.english, japanese:data.japanese}, config)
                .then(res => {
                    if (res.status === 200) {
                        this.commit('setNews', res.data.newsMessage);
                    }
                })

        },
    },

    plugins: [vuexLocal.plugin],//use localStorage
});
