<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {mapActions} from 'vuex'


export default {
  name: 'App',
  components: {},
  data() {
    return {}
  },
  mounted() {
    this.getNews();
  },
  updated() {
  },
  methods:{
    ...mapActions([
      'getNews',
    ]),
  }
  
}
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/animate.css@3.5.2/animate.min.css";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/******************** reset end ************************/

@font-face {
  font-family: 'gotham';
  src: url('./assets/fonts/gotham-book.ttf') format('truetype');
}

@font-face {
  font-family: 'didot';
  src: url('./assets/fonts/didot.otf') format('opentype');
}

@font-face {
  font-family: 'ShipporiMincho';
  src: url('./assets/fonts/ShipporiMinchoB1-Regular.otf') format('opentype');
}

/**************** fonts ***************/

/*html {*/
/*  scroll-behavior: smooth;*/
/*}*/

.ja {
  font-family: ShipporiMincho, sans-serif;
}


#app {
  font-family: gotham, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  white-space: pre-line;
}

.infos {
  font-family: gotham, sans-serif;
  font-size: 14px;
  white-space: break-spaces;
  color: #fff;
  text-transform: none;
  font-style: italic;
  text-align: center;
}

@media (max-width: 640px) {
  #app {
    margin-bottom: 62px;
  }
}

</style>
