<template>
  <div id="experience">
    <Menu page="experience"/>
    <section class="box box1">
      <div class="imgBox">
        <img src="../assets/images/experience_1.jpg" alt="experience_1" v-if="screenWidth>640"/>
        <img src="../assets/images/experience_1_m.jpg" alt="experience_1m" v-if="screenWidth<=640"/>
      </div>
    </section>
    <section class="box1 textBox">
      <p :class="$root._i18n._vm.locale ==='ja' ? 'bigText textHeader ja' : 'bigText textHeader'">{{ $t('experience.box1.text') }}</p>
    </section>

    <section class="box tripleColumns">
      <div class="tripleColumnsContainer">
        <div class="textBox" data-aos="zoom-out-right" data-aos-duration="1000" v-if="screenWidth>1610">
          <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
            <span>{{ $t('experience.box2.title.first') }}</span>
          </div>
          <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('experience.box2.text') }}</p>
        </div>
        <div class="imgComposition">
          <img src="../assets/images/experience_2-1.jpg"
               alt="experience_2-2"
               data-aos="fade-down"
               data-aos-easing="linear"
               data-aos-duration="1000"
               data-aos-delay="600"/>
          <img src="../assets/images/experience_2-2.jpg"
               alt="experience_2-1"
               data-aos="fade-down"
               data-aos-easing="linear"
               data-aos-duration="1000"/>
        </div>
        <div class="textBox" data-aos="zoom-out-right" data-aos-duration="1000" v-if="screenWidth<=1610">
          <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
            <span>{{ $t('experience.box2.title.first') }}</span>
          </div>
          <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('experience.box2.text') }}</p>
        </div>
      </div>
    </section>
    <section class="box box3">
      <div class="imgBox">
        <img src="../assets/images/experience_3.jpg"
             alt="experience_3"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"/>
      </div>
      <div class="textBox" data-aos="zoom-out-right" data-aos-duration="1000">
        <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
          <span>{{ $t('experience.box3.title.first') }}</span>
        </div>
        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('experience.box3.text') }}</p>
      </div>
    </section>
    <section class="box box4 simpleBoxImgRight">
      <div class="imgBox" v-if="screenWidth<=640">
        <img src="../assets/images/experience_4.jpg"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"
             alt="experience-4m"/>
      </div>
      <div class="textBox"
           data-aos="zoom-out-right"
           data-aos-easing="linear"
           data-aos-duration="1000"
           data-aos-delay="600">

        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('experience.box4.text') }}</p>
      </div>
      <div class="imgBox" v-if="screenWidth>640">
        <img src="../assets/images/experience_4.jpg"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"
             alt="experience4"/>
      </div>
    </section>
    <Offer textColor="#6c7485"/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import Offer from "@/components/Offer";

export default {
  name: 'experience',
  components: {
    Offer,
    Footer,
    Menu,
  },
  props: {},
  data() {
    return {
      screenWidth: screen.width
    };
  },
  mounted() {
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.textHeader.ja {
  font-style: italic;
}

.box1 {
  margin: 0;
}

.bold {
  font-weight: bold;
}

#experience .textBox {
  color: #6c7485;
}

.titleText {
  color: #6c7485;
}

.titleText::after {
  top: 113px;
}

.titleText + p {
  margin-top: 104px;
}

p {
  margin: 25px 0;
}

.box.box1 {
  height: auto;
}

.imgBox {
  height: auto;
}

.box1 .imgBox {
  width: 100%;
}


.box1 .imgBox img, .box3 .imgBox img {
  width: 100%;
  height: auto;
}

.box1.textBox {
  text-align: center;
  padding: 0;
  margin: 0 auto;
}

.box2 .textBox {
  width: 50%;
}

.simpleBoxImgRight .imgBox {
  width: 60%;
}

.simpleBoxImgRight .textBox {
  padding: 6%;
  width: 40%;
}

.box3 {
  margin: 200px 0;
}

.box3 .imgBox {
  width: 48vw;
}

.box3 .bigText {
  text-align: left;
}


.tripleColumns .tripleColumnsContainer {
  justify-content: flex-end;
}

.tripleColumns img:first-child {
  right: 45vw;
  top: 0;
  position: absolute;
  height: 66%;
}

.tripleColumns img:nth-child(2) {
  top: 6vw;
}

.tripleColumns .textBox {
  position: absolute;
  top: 54vh;
  left: 106px;
}

.box3 .textBox {
  position: absolute;
  text-align: left;
  padding: 0;
  left: 40vw;
  width: 40%;
}

.box4 .textBox {
  width: 50%;
}

.offer.textBox {
  width: 100%;
}

@media (max-width: 1610px) {
  .tripleColumns .tripleColumnsContainer {
    align-items: center;
    flex-direction: column;
  }

  .tripleColumns {
    height: auto;
  }

  .tripleColumns .textBox {
    position: relative;
    top: unset;
    left: unset;
    margin: 0px 0 0;
  }

  .tripleColumns .titleText, .tripleColumns .text {
    text-align: center;
  }

  .tripleColumns .textBox {
    margin-bottom: 45px;
  }

  .tripleColumns .textBox {
    padding-left: 0;
  }

  .tripleColumns .titleText::after {
    left: 45%;
    transform: rotate(-45deg) translateX(-50%);
  }

}

@media (max-width: 640px) {
  .tripleColumns .textBox {
    margin: 100px 0 0;
  }

  .titleText::after {
    top: 94px;
    left: 38vw;
  }

  .tripleColumns .tripleColumnsContainer {
    align-items: center;
  }

  .tripleColumns .imgComposition img:nth-child(2) {
    width: 50vw;
    top: 50px;
    right: 3vw;
  }

  .tripleColumns .imgComposition img:first-child {
    left: 1vw;
    height: 88%;
  }


  .tripleColumns .imgComposition img:first-child {
    left: 0;
    height: 72%;
  }

  .box3 {
    margin: 0 0 0px;
  }

  .box3 .bigText {
    text-align: center;
  }

  .box3 .textBox {
    position: relative;
    left: unset;
    width: 85%;
    margin-bottom: 0;
    padding: 0;
  }

  .box3 .bigText {
    margin-bottom: 0;
  }

  .box3 .imgBox {
    margin-bottom: 70px;
    width: 82vw;
  }

  .simpleBoxImgRight .textBox {
    width: 85%;
    padding: 0;
    margin: 60px 0 0;
  }


  .simpleBoxImgRight .imgBox, .simpleBoxImgRight .imgBox img {
    width: 82vw;
    height: auto;
  }

}

</style>