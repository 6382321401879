<template>
  <div id="menu" :class="active ? 'active' : 'not-active'">
   <LanguagePicker mode="normal"/>
    <div class="logContainer">
      <router-link to="/">
        <img alt="logo" :src="require(whiteMode?'../assets/images/logo.png':'../assets/images/logo2.png')" class="logo" id="logo"
             :class="!active && 'not-active'">
      </router-link>
    </div>
    <p class="logout" v-if="this.$store.getters.getUser" @click="logout">Se déconnecter</p>
    <div class="burgerContainer">
      <div class="burger" :class="whiteMode && 'whiteMode'">
        <div
            class="btn"
            :class="active ? 'active' : 'not-active'"
            @click="onBurgerClick">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul id="nav" :class="active ? 'active' : 'not-active'">
          <div class="routeContainer">
            <li v-if="user">
              <router-link to="/admin" :class="whiteMode && 'whiteMode'">Admin</router-link>
            </li>
            <li>
              <router-link to="/universe" :class="whiteMode && 'whiteMode'">{{ $t('menu.item1') }}</router-link>
            </li>
            <li>
              <router-link to="/experience" :class="whiteMode && 'whiteMode'">{{ $t('menu.item2') }}</router-link>
            </li>
            <li>
              <router-link to="/sensibility" :class="whiteMode && 'whiteMode'">{{ $t('menu.item3') }}</router-link>
            </li>
            <li>
              <router-link to="/harmony" :class="whiteMode && 'whiteMode'">{{ $t('menu.item4') }}</router-link>
            </li>
            <li>
              <router-link to="/degustation" :class="whiteMode && 'whiteMode'">{{ $t('menu.item5') }}</router-link>
            </li>
            <li>
              <router-link to="/career" :class="whiteMode && 'whiteMode'">{{ $t('menu.item6') }}</router-link>
            </li>
            <li>
              <router-link to="/contact" :class="whiteMode && 'whiteMode'">{{ $t('menu.item7') }}</router-link>
            </li>
          </div>
        </ul>
      </div>
    </div>
    <img id="booking" :src="require('../assets/images/macaron.png')" alt="reserver" v-if="screenWidth>640 && page!=='booking'" @click="onBookingClick">
    <BookingOverlay :show="showNews" mode="news" @clicked="onCloseNewsOverlay" />
    <BookingOverlay :show="showBookingPage" mode="booking" @clicked="onCloseBookingOverlay" v-if="screenWidth>640"/>
  </div>
</template>

<script>
import BookingOverlay from "@/components/BookingOverlay";
import LanguagePicker from "@/components/LanguagePicker";
import {store} from '../store/store';

export default {
  name: 'Menu',
  components: { BookingOverlay,LanguagePicker },
  props: {
    page: String
  },
  data() {
    return {
      active: screen.width > 640,
      screenWidth: screen.width,
      whiteMode: false,
      showBookingPage: false,
      showNews: false,
      user: store.getters.getUser,
    };
  },
  mounted() {
    this.$store.subscribe( (mutation, state) => {
      if (mutation.type==="setNews" && state.news && state.news.showNews) {
         window.setTimeout(()=>{
        this.showNews=true;
      },500)
      }

      if (mutation.type==="setUser" ) {
        this.user=state.user;
      }
    })


    const menu = document.getElementById("menu");
    if (this.page === "home" && screen.width > 640) {
      document.addEventListener('scroll', () => {
        const active = window.scrollY <= 0;
        this.scrollMenuManagement(active);
        if (window.scrollY > 798) {
          this.whiteMode = true;
          menu.style.background = "#fff";
          menu.style.boxShadow = "1px 2px 10px 0px rgba(0, 0, 0, 0.1)";
          menu.style.color = "#000";
        } else if (screen.width >= 640) {
          this.whiteMode = false;
          menu.style.background = "transparent";
          menu.style.boxShadow = "none";
          menu.style.color = "#fff";
        }
      })
    } else {
      this.active = false;
      this.whiteMode = true;
      menu.style.background = "#fff";
      menu.style.boxShadow = "1px 2px 10px 0px rgba(0, 0, 0, 0.1)";
      menu.style.color = "#000";
    }
  },
  methods: {
    onBurgerClick() {
      this.active = !this.active;
    },
    logout() {
      this.$store.commit("setUser", null);
    },
    scrollMenuManagement(active) {
      this.active = active;
    },
    onBookingClick() {
      this.showBookingPage = true;
    },
    onCloseBookingOverlay(value) {
      this.showBookingPage = value;
    },

     onCloseNewsOverlay(value) {
      this.showNews = value;
    },

  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

#menu {
  font-family: 'EB Garamond', sans-serif;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  padding: 1%;
  align-items: flex-start;
  width: 98%;
  height: 65px;
  color: #fff;
  margin: 0;
  position: fixed;
  z-index: 100;
  top: 0;
}

.logout{
  font-family: 'EB Garamond', sans-serif;
  position: absolute;
  top:65px;
  left:8px;
  font-size:12px;
  cursor: pointer;
}

#menu.active {
  height: 268px;
}

#booking {
  position: fixed;
  right: -1px;
  top: 14.5vh;
  z-index: 100;
  cursor: pointer;
  width: 234px;
}


#menu.active #booking {
  top: 30vh;
}



.media {
  width: 20px;
}

#nav {
  display: none;
  position: relative;
  width: 202px;
  text-align: right;
  right: 158px;
  top: 20px;
}

#nav.active {
  display: block;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

#menu li {
  margin: 10px;
}

a {
  color: #fff;
  text-decoration: none;
}

a.whiteMode {
  color: #000;
  text-decoration: none;
}

.logoContainer {
  width: 40%;
}

.logo {
  transition: all .5s;
  width: 60px;
}

.logo.not-active {
  width: 50px;
}

.burgerContainer {
  width: 28%;
  display: flex;
  justify-content: flex-end;
}

.burger {
  border-radius: 2px;
  width: 40px;
  height: 50px;
  color: #fff;
}

.btn {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.burger span, .bookingContainer .btn span {
  display: block;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  height: 1px;
  background: #fff;
  transition: all .3s;
  position: relative;
}


.burger.whiteMode span {
  background: #000;
}

.burger .not-active span + span {
  margin-top: 7px;
}

.burger .active span + span {
  margin-top: 21px;
}


.burger .active span:nth-child(1) {
  animation: ease .7s top forwards;
}

.burger .not-active span:nth-child(1) {
  animation: ease .7s top-2 forwards;
}

.burger .active span:nth-child(2) {
  animation: ease .7s scaled forwards;
}

.burger .not-active span:nth-child(2) {
  animation: ease .7s scaled-2 forwards;
}

.burger .active span:nth-child(3) {
  animation: ease .7s bottom forwards;
}

.burger .not-active span:nth-child(3) {
  animation: ease .7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 22px;
    transform: rotate(0);
  }
  100% {
    top: 22px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 22px;
    transform: rotate(45deg);
  }
  50% {
    top: 22px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 22px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 22px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 640px) {
  .logo, .logo.not-active {
    width: 40px;
  }

  #menu, #menu.active {
    height: 60px;
  }

  #menu {
    align-items: center;
    padding: 2% 5%;
    width: 91%;
  }

  .burger {
    margin-top: 30px;
  }

  #nav {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: #fff;
    z-index: -1;
    left: 0;
    text-align: center;
    align-items: center;
    top: 0;
  }

  #nav a {
    color: #000;
  }

  #nav.active {
    display: flex;
    justify-content: center;
  }

  .routeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
