<template>
  <div id="home">
    <Menu page="home"/>
    <div class="box slider">
      <hooper :settings="hooperSettings">
        <slide>
          <div class="hooper-slide-inner">
            <img :src="require('../assets/images/slider6_pc.jpg')" alt="slider6_pc" v-if="screenWidth>640"/>
            <img :src="require('../assets/images/slider3_m.jpg')" alt="slider3_m" v-if="screenWidth<=640"/>
          </div>
        </slide>
        <slide>
          <div class="hooper-slide-inner">
            <img :src="require('../assets/images/slider2_pc.jpg')" alt="slider2_pc" v-if="screenWidth>640"/>
            <img :src="require('../assets/images/slider2_m.jpg')" alt="slider2_m" v-if="screenWidth<=640"/>
          </div>
        </slide>
        <slide>
          <div class="hooper-slide-inner">
            <img :src="require('../assets/images/slider3_pc.jpg')" alt="slider3_pc" v-if="screenWidth>640"/>
            <img :src="require('../assets/images/slider1_m.jpg')" alt="slider1_m" v-if="screenWidth<=640"/>
          </div>
        </slide>
        <slide>
          <div class="hooper-slide-inner">

            <img :src="require('../assets/images/slider4_pc.jpg')" alt="slider4_pc" v-if="screenWidth>640"/>
            <img :src="require('../assets/images/slider4_m.jpg')" alt="slider4_m" v-if="screenWidth<=640"/>
          </div>
        </slide>
        <slide>
          <div class="hooper-slide-inner">

            <img :src="require('../assets/images/slider5_pc.jpg')" alt="slider5_pc" v-if="screenWidth>640"/>
            <img :src="require('../assets/images/slider5_m.jpg')" alt="slider5_m" v-if="screenWidth<=640"/>
          </div>
        </slide>
        <slide>
          <div class="hooper-slide-inner">
            <img :src="require('../assets/images/slider1_pc.jpg')" alt="slider1_pc" v-if="screenWidth>640"/>

            <img :src="require('../assets/images/slider6_m.jpg')" alt="slider6_m" v-if="screenWidth<=640"/>
          </div>
        </slide>
        <slide>
          <div class="hooper-slide-inner">

            <img :src="require('../assets/images/slider7_pc.jpg')" alt="slider7_pc" v-if="screenWidth>640"/>
            <img :src="require('../assets/images/slider7_m.jpg')" alt="slider7_m" v-if="screenWidth<=640"/>
          </div>
        </slide>
      </hooper>
      <div id="scroll">
        <img :src="require('../assets/images/scroll.png')" alt="scroll"/>
        <p>scroll</p>
      </div>
    </div>

    <div class="box universe tripleColumns">
      <div class="tripleColumnsContainer">
        <div class="textBox" data-aos="fade-right" data-aos-duration="1000">
          <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
            <span>{{ $t('home.universeBox.title.first') }}</span>
            <span>{{ $t('home.universeBox.title.second') }}</span>
          </div>
          <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('home.universeBox.text') }}</p>
          <router-link to="universe">
            <button class="cta">{{ $t('home.universeBox.cta') }}</button>
          </router-link>
        </div>
        <div class="imgComposition">
          <img src="../assets/images/home_1-1.png" alt="home_1-1"
               data-aos="fade-down"
               data-aos-delay="800"
               data-aos-easing="linear"
               data-aos-duration="1000"/>
          <img src="../assets/images/home_1-2.jpg" alt="home_1-2" data-aos="fade-down"
               data-aos-easing="linear"
               data-aos-duration="1000"/>
        </div>
      </div>
    </div>

    <div class="box box3-home">
      <div class="imgBox">
        <img data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000" src="../assets/images/home_2.jpg" alt="home_2"/>
      </div>
    </div>

    <div class="box sensibility">
      <div class="textBox" data-aos="zoom-out-right" data-aos-duration="1000">
        <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
          <span>{{ $t('home.sensibilityBox.title.first') }}</span>
          <span>{{ $t('home.sensibilityBox.title.second') }}</span>
        </div>
        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('home.sensibilityBox.text') }}</p>
        <router-link to="sensibility">
          <button class="cta">{{ $t('home.sensibilityBox.cta') }}</button>
        </router-link>
      </div>
      <div class="imgBox alignRight">
        <img src="../assets/images/home_3.jpg"
             alt="home-3"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"/>
      </div>
    </div>

    <div class="box experience simpleBox">
      <div class="textBox" data-aos="fade-left" data-aos-duration="1000" v-if="screenWidth<=640">
        <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
          <span>{{ $t('home.experienceBox.title.first') }}</span>
          <span>{{ $t('home.experienceBox.title.second') }}</span>
        </div>
        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('home.experienceBox.text') }}</p>
        <router-link to="experience">
          <button class="cta">{{ $t('home.experienceBox.cta') }}</button>
        </router-link>
      </div>
      <div class="imgBox">
        <img src="../assets/images/home_4.jpg"
             alt="home_4"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"/>
      </div>
      <div class="textBox" data-aos="fade-left" data-aos-duration="1000" v-if="screenWidth>640">
        <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
          <span>{{ $t('home.experienceBox.title.first') }}</span>
          <span>{{ $t('home.experienceBox.title.second') }}</span>
        </div>
        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('home.experienceBox.text') }}</p>
        <router-link to="experience">
          <button class="cta">{{ $t('home.experienceBox.cta') }}</button>
        </router-link>

      </div>
    </div>

    <div class="box harmony simpleBox">
      <div class="textBox"
           data-aos="fade-up"
           data-aos-easing="linear"
           data-aos-duration="1000">
        <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
          <span>{{ $t('home.harmonyBox.title.first') }}</span>
          <span>{{ $t('home.harmonyBox.title.second') }}</span>
        </div>
        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('home.harmonyBox.text') }}</p>
        <router-link to="harmony">
          <button class="cta">{{ $t('home.harmonyBox.cta') }}</button>
        </router-link>
      </div>
      <div class="imgBox alignRight">
        <img src="../assets/images/home_5.jpg"
             alt="home_5"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"/>
      </div>
    </div>

    <div class="box degustation tripleColumns">
      <div class="tripleColumnsContainer">
        <div class="textBox"
             data-aos="fade-right"
             data-aos-easing="linear"
             data-aos-duration="800">
          <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
            <span>{{ $t('home.degustationBox.title.first') }}</span>
            <span>{{ $t('home.degustationBox.title.second') }}</span>
          </div>
          <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('home.degustationBox.text') }}</p>
          <router-link to="degustation">
            <button class="cta">{{ $t('home.degustationBox.cta') }}</button>
          </router-link>
        </div>

        <div class="imgComposition">
          <img src="../assets/images/home_6-1.png"
               alt="home_6-1"
               data-aos="fade-down"
               data-aos-easing="linear"
               data-aos-duration="1000"
               data-aos-delay="600"/>
          <img src="../assets/images/home_6-2.jpg"
               alt="home_6-2"
               data-aos="fade-down"
               data-aos-easing="linear"
               data-aos-duration="1000"/>
        </div>
      </div>
    </div>
    <Offer/>
    <Footer/>
  </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import Footer from '../components/Footer';
import Menu from '../components/Menu';

import 'hooper/dist/hooper.css';
import Offer from "@/components/Offer";

export default {
  name: 'Home',
  components: {
    Offer,
    Footer,
    Menu,
    Hooper,
    Slide,
  },
  props: {},
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        wheelControl: false,
        keysControl: false,
        autoPlay: true,
        touchDrag: false,
        mouseDrag: false,
        shortDrag: false,
        hoverPause: false,
        transition: 1500,
        playSpeed: 6000,
      },
      screenWidth: screen.width,
    };
  },
  mounted() {
    const scroll = document.getElementById("scroll");
    window.onscroll = () => {
      if (window.scrollY > 10) {
        scroll.style.display = "none";
      } else {
        scroll.style.display = "block";
      }
    }
  },
  methods: {}
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.mobile {
  display: none;
}

#scroll {
  font-family: "EB Garamond", sans-serif;
  color: #a4abb3;
  position: absolute;
  top: 90vh;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-50%);
}


#scroll p {
  margin: 10px 0;
}


button {
  border: none;
  font-family: 'EB Garamond', serif;
  font-size: 34px;
  color: #660032;
  background: none;
  outline: none;
  position: relative;
}

.cta{
    cursor: pointer;
}

button.cta {
  padding: 0;
}

.cta:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(1);
  height: 2px;
  bottom: 0;
  left: 0;
  background: #660032;
  transform-origin: bottom right;
  transition: transform 0.6s ease-out;
}

p + .cta {
  margin-top: 6px;
}

.cta:hover:after {
  transform: scaleX(0.2);
  transform-origin: bottom right;
}

#home {
  scroll-snap-type: y mandatory;
  overflow: hidden;
}

.hooper {
  height: 100%;
  overflow: hidden;
}

.hooper-track {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
}

.hooper-slide, .hooper-slide.is-prev, .hooper-slide.is-next {
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
}


.hooper-slide {
  flex-shrink: 0;
  overflow: hidden;
}

.hooper-slide.is-active.is-current .hooper-slide-inner {
  z-index: 1;
  animation: sliding 35s cubic-bezier(0, 0, 1, 1);
  -moz-animation: sliding 35s cubic-bezier(0, 0, 1, 1);
  -webkit-animation: sliding 35s cubic-bezier(0, 0, 1, 1);
}

@keyframes sliding {
  0% {
    transform: translateX(50%);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
  }

  100% {
    transform: translateX(-70%);
    -moz-transform: translateX(-70%);
    -webkit-transform: translateX(-70%);
    /*opacity: 0;*/
    /*-khtml-opacity: 0;*/
    /*-moz-opacity: 0;*/
  }
}

.hooper-slide-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.hooper-slide-inner img {
  width: 120%;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}


.hooper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


.hooper .icon {
  background: #fff;
}

.box.slider {
  margin-top: 0;
}

.text {
  font-size: 20px;
  color: #646470;
  line-height: 24px;
}

.text.ja, .bigText.ja {
  font-family: ShipporiMincho, sans-serif;
}

.text, .bigText {
  white-space: normal;
}

.bigText {
  font-size: 30px;
  color: #646470;
  width: 100%;
}

/*.textHeader{*/
/*  white-space: break-spaces;*/
/*}*/

.bigText.textHeader {
  margin: 170px 0;
}

.textBox {
  position: relative;
  z-index: 10;
  padding-left: 8vw;
  width: 500px;
}

.author {
  color: #646470;
  font-family: didot, serif;
  font-style: italic;
  font-size: 28px;
}

span.author {
  display: block;
  margin-top: 8px;
}

.ja .author {
  font-family: ShipporiMincho, sans-serif;
}

.degustation .textBox {
  position: absolute;
  top: 40vh;
  left: 15vw;
}

.experience .textBox {
  width: 40%;
  padding: 10vw;
}

.imgBox {
  height: 100vh;
  overflow: hidden;
  width: 70%;
}

.imgBox img {
  height: 85%;
}

.box.experience {
  padding-left: 8vw;
}

.experience .imgBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgBox.alignRight {
  text-align: right;
}

.titleText {
  position: relative;
  font-family: didot, serif;
  font-style: italic;
  text-transform: uppercase;
  font-size: 66px;
  color: #646470;
}

.titleText.ja {
  font-family: ShipporiMincho, sans-serif;
}

.titleText::after {
  content: '';
  height: 1px;
  width: 50px;
  display: block;
  top: 180px;
  transform: rotate(-45deg);
  position: absolute;
  background: #807d7d;
}

.titleText.ja::after {
  top: 113px
}

.titleText span {
  display: block;
}

.titleText + p, .titleText + button {
  margin-top: 104px;
}


.box {
  overflow: hidden;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  scroll-snap-align: center;
  margin: 80px 0;
}

.box3-home {
  margin-top: 200px;
}

.sensibility {
  margin-bottom: 0px;
}

.experience {
  margin-top: 0px;
}

.simpleBox p {
  top: 40%;
  left: 40%;
}

.sensibility p {
  top: 70%;
}

.sensibility .imgBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.tripleColumnsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.degustation .tripleColumnsContainer {
  justify-content: flex-end;
}

.universe .tripleColumnsContainer {
  align-items: center;
}


.tripleColumns .imgComposition {
  width: 70%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tripleColumns .imgComposition img:nth-child(2) {
  width: 40vw;
}

.universe img:first-child {
  z-index: 1;
  left: 10vw;
  width: 25vw;
}


.degustation img:first-child {
  right: 45vw;
  top: 0;
  position: absolute;
  height: 66%;
}

.degustation img:nth-child(2) {
  top: 6vw;
}

.tripleColumns img {
  position: relative;
}


@media (max-width: 640px) {
  .hooper-slide-inner img {
    width: 113%;
  }

  .titleText.ja::after {
    top: 96px;
  }

  #scroll {
    top: 80vh;
  }

  .desktop, #booking {
    display: none;
  }

  .mobile {
    display: block;
  }

  .bigText.textHeader {
    margin: 100px 0;
  }

  .text {
    font-size: 17px;
  }

  .bigText {
    font-size: 19px;
  }

  .cta {
    font-size: 28px;
  }

  .textBox, .experience .textBox {
    padding: 0;
    width: 85%;
  }

  .textBox, .titleText, .bigText {
    text-align: center;
  }

  .textBox {
    margin-bottom: 50px;
  }

  .author {
    text-align: center;
  }


  span.author {
    margin-left: 0;
  }

  .offer.textBox {
    margin: 100px 0 130px;
  }

  .titleText {
    font-size: 44px;
  }

  .titleText::after {
    left: 35vw;
    top: 135px;
  }

  .box {
    margin: 70px 0;
  }

  .box, .tripleColumnsContainer {
    flex-direction: column;
    overflow: visible;
  }

  .box.slider {
    height: 100vh;
  }

  .box, .box3-home, .imgBox img, .imgBox {
    height: auto;
  }

  .textBox {
    width: 85%;
    padding: 0;
  }

  .imgBox {
    width: 80%;
  }

  .degustation .tripleColumnsContainer {
    align-items: center;
  }

  .tripleColumns .imgComposition {
    margin: 25px 0;
    width: 100%;
    justify-content: flex-end;
    text-align: right;
  }

  .tripleColumns .imgComposition img:nth-child(2) {
    width: 65vw;
  }

  .universe img:first-child {
    z-index: 1;
    left: 22vw;
    width: 56vw;
  }

  .box3-home {
    align-items: flex-start;
  }

  .box3-home img {
    width: 100%;
  }

  .sensibility .imgBox {
    width: 100%;
  }

  .sensibility img {
    height: auto;
    width: 100%;
  }

  .box.experience {
    padding-left: 0;
  }

  .experience .imgBox img {
    width: 100%;
  }

  .harmony .imgBox {
    width: 100%;
    text-align: right;
  }

  .harmony img {
    width: 80%;
  }

  .degustation .imgComposition img:nth-child(2) {
    width: 50vw;
    top: 50px;
  }

  .degustation .imgComposition img:first-child {
    left: 1vw;
    height: 88%;
  }

  .degustation .textBox {
    position: relative;
    top: unset;
    left: unset;
  }

}

</style>