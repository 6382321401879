<template>
  <div id="harmony">
    <Menu page="harmony"/>
    <section class="box box1">
      <div class="imgBox">
        <img src="../assets/images/harmony_1.jpg" alt="harmony1" v-if="screenWidth>640"/>
        <img src="../assets/images/harmony_1_m.jpg" alt="harmony1m" v-if="screenWidth<=640"/>
      </div>
    </section>

    <div class="box harmony simpleBox">
      <div class="textBox"
           data-aos="fade-up"
           data-aos-easing="linear"
           data-aos-duration="1000">
        <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
          <span>{{ $t('harmony.box1.title.first') }}</span>
          <span>{{ $t('harmony.box1.title.second') }}</span>
        </div>
        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('harmony.box1.text') }}</p>
      </div>
      <div class="imgBox">
        <img src="../assets/images/harmony_2.jpg"
             alt="harmony2"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"/>
      </div>
    </div>
    <Offer/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import Offer from "@/components/Offer";

export default {
  name: 'harmony',
  components: {
    Offer,
    Footer,
    Menu,
  },
  props: {},
  data() {
    return {
      screenWidth: screen.width,
    };
  },
  mounted() {
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.box1 {
  margin: 0;
}

.bold {
  font-weight: bold;
}

p {
  margin: 25px 0;
}

.cta {
  margin: 150px 0 210px;
}

.box.box1 {
  height: auto;
}

.harmony {
  margin-top: 210px;
  margin-bottom: 0px;
}

.imgBox {
  height: auto;
}

hr {
  height: 1px;
  width: 500px;
  background: #660032;
  margin: 200px auto;
}

.box1 .imgBox {
  width: 100%;
}


.box1 .imgBox img, .box3 .imgBox img {
  width: 100%;
  height: auto;
}

.imgBox img {
  height: 100%;
  width: 100%;
}

.box1.textBox {
  text-align: center;
  padding: 0;
  margin: 0 auto;
}

.box2 .textBox {
  width: 50%;
}

.offer.textBox {
  margin: 126px 0 200px;
}

.titleText::after {
  top: 170px;
}

.titleText + p {
  margin-top: 84px;
}

.menu {
  text-align: center;
}

.titleText.ja::after {
  top: 113px
}

.harmony .textBox {
  padding: 5%;
}

.menuBox .text {
  color: #660032;
  font-family: "EB Garamond", sans-serif;
  font-weight: 400;
  font-size: 36px;
  margin: 45px 0;
}

@media (max-width: 1290px) {
  .titleText::after {
    top: 136px;
    left: 50%;
    transform: rotate(-45deg) translateX(-50px);
  }

  .imgBox img {
    height: 100%;
    width: auto;
  }

  .box, .tripleColumnsContainer {
    flex-direction: column;
    overflow: visible;
  }

  .textBox, .titleText, .bigText {
    text-align: center;
  }


}

@media (max-width: 640px) {
  .imgBox {
    text-align: center;
  }

  hr {
    width: 200px;
  }

  .titleText.ja::after{
    top: 86px;
  }

  .titleText::after {
    top: 135px;
    left: 32vw;
    transform: rotate(-45deg);
  }

  .harmony .textBox {
    padding: 0;
    width: 85%;
  }

  .menuBox .text {
    font-size: 28px;
  }

  .harmony {
    margin-top: 60px;
  }

  .harmony img {
    width: 100%;
  }

}

</style>