<template>
  <div id="login">
    <Menu page="login"/>
    <form :class="$root._i18n._vm.locale ==='ja' ? 'newsletter ja' : 'newsletter'">
        <input type="email" placeholder="email" v-model="email"/>
        <input type="password" placeholder="mot de passe" v-model="password" />
        <div class="button" @click="authenticate">Se connecter</div>
        <p class="text" v-if="showMessage">{{ this.message}}</p>
    </form>
  </div>
</template>

<script>
import Menu from '../components/Menu';
import {mapActions} from 'vuex'
import {store} from '../store/store';
import router from '../router';


export default {
  name: 'login',
  components: {
    Menu,
  },
  props: {},
  data() {
    return {
      screenWidth: screen.width,
      email: '',
      password: '',
      showMessage: false,
      message:"La combinaison email/mot de passe est incorrecte"
    };
  },

  mounted() {
    const user = store.getters.getUser;
    if(user){
      router.push('/admin')
    }

  },
  methods:{
    ...mapActions([
      'login',
    ]),
     displayMessage(message){
      this.message=message;
      this.showMessage = true;
      window.setTimeout(() => {
        this.showMessage = false;
      }, 3000)
    },
    async authenticate(){
      try {
        await this.login({email:this.email, password: this.password})
      } catch (error) {
        this.displayMessage("La combinaison email/mot de passe est incorrecte")
      }
    }
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');

#login{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width:100%;
}
form {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
}

form input{
  display: block;
  margin :5px 0;
  padding: 8px 5px;
  
}

.button {
  margin: 40px auto 0;
  display: inline-block;
  padding: 10px 40px;
  height: 19px;
  border: 2px solid #fff;
  color: #fff;
  background: #660032;
  cursor: pointer;
  font-size: 18px;
  font-family: inherit;
}
</style>