<template>
  <footer>
    <section class="first">
      <div class="contact">
        <img class="footerLogo" :src="require('../assets/images/logoFooter.png')" alt="">
        <p>
          <span class="bold">5 Rue Coq Héron, 75001 Paris</span>
          <span>+33 1 42 33 14 74</span>
          <span>reservationkei@gmail.com</span>
        </p>
      </div>
      <div class="exterior">
        <p>
          <span class="bold">{{ $t('footer.contact.frenchContact') }}</span>
          <span>Sabrina Ubinana</span>
          <span>sabrina.ubinana@gmail.com</span>
        </p>
        <p>
          <span class="bold">{{ $t('footer.contact.japaneseContact') }}</span>
          <span>nao.restaurantkei@gmail.com</span>
        </p>
      </div>
      <div class="right"></div>
    </section>
    <section class="second">
      <div class="mainContainer">
        <div class="social">
          <a href="https://www.instagram.com/restaurantkei/?hl=fr" target="_blank">Instagram</a>
          <a href="https://m.facebook.com/www.restaurantkei.fr/?locale2=fr_FR" target="_blank">Facebook</a>
        </div>
        <form :class="$root._i18n._vm.locale ==='ja' ? 'newsletter ja' : 'newsletter'">
          <p>{{ $t('footer.newsletter.sentence') }}</p>
          <input id="condition" type="checkbox"/>
          <label for="condition" id="label" class="side-label">{{ $t('footer.newsletter.checkbox') }}</label>
          <p class="text" v-if="showMessage">{{ $t('footer.newsletter.msg') }}</p>
          <div class="input">
            <input type="email" :placeholder="$t('footer.newsletter.inputPlaceholder')" v-model="value"/>
            <div class="button" @click="addEmail">{{ $t('footer.newsletter.buttonText') }}</div>
          </div>
        </form>
        <div class="picto">
          <a href="https://guide.michelin.com/fr/fr/ile-de-france/paris/restaurant/kei" target="_blank">
            <img class="" :src="require('../assets/images/stars.png')" alt="michelin">
          </a>
          <a href="https://lesgrandestablesdumonde.com/restaurant/kei-restaurant/" target="_blank">
            <img class="" :src="require('../assets/images/world.png')" alt="world">
          </a>
          <a href="https://fr.gaultmillau.com/restaurants/kei-kobayashi?locale=fr-FR" target="_blank">
            <img class="" :src="require('../assets/images/gaultmillau-green.png')" alt="gaultmillau">
          </a>
        </div>
      </div>

      <div class="credit">
        <router-link to="legal-mention">{{ $t('footer.legal') }}</router-link>
        <div>
          <p>{{ $t('footer.design') }}</p>
          <p>{{ $t('footer.photo') }}</p>
        </div>
      </div>
    </section>
    <section class="book" v-if="screenWidth<=640">
      <p @click="onBookingClick">{{ $t('footer.bookingSmartphone') }}</p>
      <BookingOverlay :show="showBookingPage" mode="booking" @clicked="onCloseBookingOverlay" v-if="screenWidth<=640"/>
    </section>
  </footer>
</template>

<script>
import axios from "axios";
import BookingOverlay from "@/components/BookingOverlay";
import {mapActions} from 'vuex'


export default {
  name: 'Footer',
  components: { BookingOverlay },
  props: {
    msg: String
  },
  data() {
    return {
      value: '',
      showMessage: false,
      screenWidth: screen.width,
      showBookingPage: false,
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'registerEmail',
    ]),
    addEmail() {
      if (this.value) {
        if (document.getElementById("condition").checked) {
          const regexEmail = new RegExp(/^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,}$/);
          if (!regexEmail.test(this.value)) {
            throw new Error("not en email");
          }
         this.registerEmail();
          this.showMessage = true;
          window.setTimeout(() => {
            this.showMessage = false;
          }, 1500)
          this.value = "";
          document.getElementById("label").style.color = "#393942";
        } else {
          document.getElementById("label").style.color = "#660032";
        }
      }
    },
    onBookingClick() {
      this.showBookingPage = true;
    },
    onCloseBookingOverlay(value) {
      this.showBookingPage = value;
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');

footer .first, footer .second .mainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  padding: 40px;
}

.social a {
  display: block;
  color: #a4abb3;
  text-decoration: none;
  font-size: 20px;
  margin: 15px 0;
  text-transform: uppercase;
}

.book {
  width: 100%;
  background: #660032;
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  z-index: 1000;
  padding: 10px 0;
  color: #fff;
}

.book a {
  color: #fff;
  text-decoration: none;
}

.text {
  color: #a4abb3;
}

p span {
  display: block;
  margin: 5px 0;
}

span.bold {
  font-family: 'EB Garamond', sans-serif;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 700;
}

.contact {
  font-style: italic;
}

.macaaronFooter {
  width: 150px;
}

.first {
  background: #edeef0;
  color: #646470;
  font-family: 'EB Garamond', sans-serif;
}

.right {
  text-align: right;
  visibility: hidden;
}

.second {
  background: #646470;
  color: #a4abb3;
}

.exterior {
  text-align: center;
}

p {
  margin: 5px;
}

.exterior > * {
  margin: 66px 0;
  position: relative;
}

.footerLogo {
  width: 60px;
  margin-bottom: 15px;
}

.social, .picto, .contact, .right {
  width: 25%;
  position: relative;
}

.social::before, .exterior p:nth-child(2)::before {
  content: '';
  height: 1px;
  width: 40px;
  display: block;
  top: -32px;
  transform: rotate(-45deg);
  position: absolute;
  background: #a4abb3;
}

.social::before {
  top: -32px;
}

.exterior p:nth-child(2)::before {
  top: -32px;
  left: 22.5vw;
}

.exterior, .newsletter {
  text-align: center;
  width: 50%;
}

.picto {
  display: flex;
  align-items: center;
  justify-content: center;
}

.picto > * {
  margin: 0 25px;
}

.picto img:first-child {
  width: 100px;
}

.picto img {
  width: 80px;
}

.blocked {
  margin-top: 40px;
  color: rgba(255, 255, 255, .3);
  text-align: center;
}

.newsletter {
  text-align: center;
  font-size: 22px;
}

.newsletter.ja {
  font-family: ShipporiMincho, sans-serif;
}

.input > * {
  margin: 0 8px;
}

input {
  padding: 4px 10px;
  color: #a4abb3;
  height: 36px;
  width: 300px;
  border: none;
  background: #4b4b5e;
}

.button {
  margin: 40px auto 0;
  display: inline-block;
  padding: 10px 40px;
  height: 19px;
  border: 2px solid #fff;
  color: #393942;
  background: #fff;
  cursor: pointer;
  font-size: 18px;
  font-family: inherit;
}


input[type="checkbox"] {
  display: none;
}

.side-label {
  display: inline-block;
  color: #393942;
  position: relative;
  margin: 10px 0;
  line-height: 25px;
  padding-left: 35px;
  cursor: pointer;
}

.side-label::before, .side-label::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
}

.side-label::before {
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
}

input:focus + .side-label::before {
  border-color: #9ab593;
}

.side-label::after {
  display: none;
  width: 16px;
  height: 16px;
  margin: 4px;
  background-color: #9ab593;
}

input:checked + .side-label::after {
  display: block;
}

#permitted:checked ~ .blocked {
  display: none;
}

.credit {
  display: flex;
  padding: 40px;
  justify-content: space-between;
}

.credit a {
  color: #a4abb3;
}

.credit p:first-child {
  margin: 0;
}

.credit p {
  display: inline-block;
  margin: 0 15px
}

@media (max-width: 1375px) {
  .exterior p:nth-child(2)::before, .social::before {
    left: 41.5vw;
  }

  footer .first, footer .second .mainContainer {
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .credit {
    flex-direction: column;
    text-align: center;
    margin-top: 25px;
    padding: 0;
  }

  .credit p {
    display: block;
  }

  .credit > *, .credit div > * {
    margin: 20px 0;
  }

  .credit p:last-child, .credit div {
    margin-bottom: 0;
  }

  .contact {
    margin-top: 25px;
  }

  .macaaronFooter {
    display: none;
  }

  .social, .picto, .contact, .right, .exterior, .newsletter {
    text-align: center;
    width: 100%;
  }


  .newsletter {
    margin: 25px 0 40px;
  }


  .picto > * {
    margin: 0 18px;
  }

  .picto img:first-child {
    width: 70px;
  }

  .picto img {
    width: 50px;
  }

  .input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .input > * {
    display: block;
    margin: 8px 0;
    width: 88%;
  }

  input, .button {
    text-align: center;
    width: 93%;
  }

  .button {
    padding: 10px 0;
  }

  .second {
    padding: 72px 10px 40px;
  }

}

</style>
