<template xmlns:mailto="http://www.w3.org/1999/xhtml">
  <div id="bookingPage">
    <Menu page="booking"/>
    <div>
      <img :src="require('../assets/images/logo.png')" class="logo" alt="logo"/>
      <p>{{ $t('menu.bookingPageAlternate') }} <a href="mailto: reservationkei@gmail.com">reservationkei@gmail.com</a></p>
    </div>
  </div>
</template>

<script>
import Menu from '../components/Menu';

export default {
  name: 'harmony',
  components: {
    Menu,
  },
  props: {},
  data() {
    return {};
  },
  mounted() {
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');


#bookingPage {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

a {
  color: #000;
  text-decoration: underline;
}

#bookingPage div > * {
  margin: 10px 0;
  color: #000;
}

@media (max-width: 640px) {

}

</style>