<template>
  <div id="legalMention">
    <Menu page="legal-mention"/>
    <div class="container">
      <div class="titleText">
        <p>{{ $t('footer.legal') }}</p>
      </div>
      <p class="text"><span>{{ $t('legalMention.part1.title') }}</span>{{ $t('legalMention.part1.text') }}</p>
      <p class="text"><span>{{ $t('legalMention.part2.title') }}</span>{{ $t('legalMention.part2.text') }}</p>
      <p class="text">{{ $t('footer.photo') }}</p>
      <p class="text"><span>{{ $t('legalMention.part3.title') }}</span>{{ $t('legalMention.part3.text') }}</p>
      <p class="text"><span>{{ $t('legalMention.part4.title') }}</span>{{ $t('legalMention.part4.text') }}</p>
      <p class="text"><span>{{ $t('legalMention.part5.title') }}</span>{{ $t('legalMention.part5.text') }}</p>
    </div>

  </div>
</template>

<script>
import Menu from '../components/Menu';

export default {
  name: 'legalMention',
  components: {
    Menu,
  },
  props: {},
  data() {
    return {};
  },
  mounted() {
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.titleText {
  font-family: gotham, sans-serif;
  font-weight: bold;
  font-style: normal;
}

.text {
  color: #000;
  margin: 20px 0;
  white-space: break-spaces;
}

.text span {
  display: block;
  font-weight: bold;
}

.titleText::after {
  top: -60px;
  background: #000;
  left: 50%;
  transform: rotate(-45deg) translateX(-50%);
}

.titleText {
  color: #000;
  text-align: center;
  font-size: 35px;
}

.titleText span:nth-child(2) {
  font-size: 30px;
  text-transform: none;
}

#legalMention {
  width: 100%;
  text-align: center;
}

.container {
  padding: 20vh 2% 0;
  width: 96%;
}

@media (max-width: 640px) {

  .titleText::after {
    top: -52px;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%);
  }

  .titleText {
    font-size: 30px;
  }

  .titleText span:nth-child(2) {
    font-size: 25px;
  }

}

</style>