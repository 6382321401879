<template>
  <div id="gift">
    <Menu page="gift"/>
    <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'" data-aos="fade-up"
         data-aos-easing="linear"
         data-aos-duration="1000">
      <span>{{ $t('booking.text1') }}</span>
      <span>{{ $t('booking.text2') }}</span>
    </div>
  </div>
</template>

<script>
import Menu from '../components/Menu';

export default {
  name: 'harmony',
  components: {
    Menu,
  },
  props: {},
  data() {
    return {};
  },
  mounted() {
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');


.titleText::after {
  top: -60px;
  background: #000;
  left: 50%;
  transform: rotate(-45deg) translateX(-50%);
}

.titleText {
  color: #000;
  text-align: center;
  font-size: 35px;
}

.titleText span:nth-child(2) {
  font-size: 30px;
  text-transform: none;
}

#gift {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

@media (max-width: 640px) {

  .titleText::after {
    top: -52px;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%);
  }

  .titleText {
    font-size: 30px;
  }

  .titleText span:nth-child(2) {
    font-size: 25px;
  }

}

</style>