<template>
  <div id="career">
    <Menu page="career"/>
    <section class="box box1">
      <div class="imgBox">
        <img src="../assets/images/career_1.jpg" alt="career1" v-if="screenWidth>640"/>
        <img src="../assets/images/career_1_m.jpg" alt="career1m" v-if="screenWidth<=640"/>
      </div>
    </section>

    <section class="box career simpleBox">
      <div class="textBox"
           data-aos="fade-up"
           data-aos-easing="linear"
           data-aos-duration="1000">
        <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
          <span>{{ $t('career.box1.title.first') }}</span>
          <span>{{ $t('career.box1.title.second') }}</span>
        </div>
        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('career.box1.text') }}</p>
      </div>
      <div class="imgBox">
        <img src="../assets/images/career_2.jpg"
             alt="career2"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"/>
      </div>
    </section>

    <section class="jobs">
      <p class="title">{{ $t('career.jobs.title1') }}</p>
      <div class="job">
        <p>{{ $t('career.jobs.job1') }}</p>
        <a href="mailto:recrutement.restaurantkei@gmail.com" target="_blank">
        <button class="cta">
          {{ $t('career.cta') }}
        </button>
      </a>
      </div>
      <div class="job">
        <p>{{ $t('career.jobs.job2') }}</p>
        <a href="mailto:recrutement.restaurantkei@gmail.com" target="_blank">
        <button class="cta">
          {{ $t('career.cta') }}
        </button>
      </a>
      </div>
      <div class="job">
        <p>{{ $t('career.jobs.job3') }}</p>
        <a href="mailto:recrutement.restaurantkei@gmail.com" target="_blank">
        <button class="cta">
          {{ $t('career.cta') }}
        </button>
      </a>
      </div>
      <div class="job">
        <p>{{ $t('career.jobs.job4') }}</p>
        <a href="mailto:recrutement.restaurantkei@gmail.com" target="_blank">
        <button class="cta">
          {{ $t('career.cta') }}
        </button>
      </a>
      </div>
      <p class="title">{{ $t('career.jobs.title2') }}</p>
      <a href="mailto:recrutement.restaurantkei@gmail.com" target="_blank">
        <button class="cta">
          {{ $t('career.cta') }}
        </button>
      </a>
    </section>


    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer';
import Menu from '../components/Menu';

export default {
  name: 'career',
  components: {
    Footer,
    Menu,
  },
  props: {},
  data() {
    return {
      screenWidth: screen.width,
    };
  },
  mounted() {
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.box1 {
  margin: 0;
}

.title{
    font-family: didot, serif;
    font-style: italic;
    font-size: 60px;
    color: #646470;
    text-align: center;
    margin: 120px 0 80px;
}

.jobs{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
}

.job{
  width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 110px;
  height:155px;
  background: #fff;
  box-shadow: 1px 1px 20px 3px rgba(0,0,0,0.2);
  margin: 25px 0;
}

.job p{
  font-family: 'EB Garamond', serif;
  font-weight: 700;
  font-size: 36px;
  color: #4b4b5e;
  text-align: center;
  margin: 0;
  white-space: normal;
}

.text{
  white-space: break-spaces;
}

.bold {
  font-weight: bold;
}

p {
  margin: 25px 0;
}

.cta {
  margin: 0;
}

.box.box1 {
  height: auto;
}

.career {
  margin-bottom: 0px;
}

.imgBox {
  height: auto;
}

hr {
  height: 1px;
  width: 500px;
  background: #660032;
  margin: 200px auto;
}

.box1 .imgBox {
  width: 100%;
}


.box1 .imgBox img, .box3 .imgBox img {
  width: 100%;
  height: auto;
}

.imgBox img {
  height: 100%;
  width: 100%;
}

.box1.textBox {
  text-align: center;
  padding: 0;
  margin: 0 auto;
}

.box2 .textBox {
  width: 50%;
}

.offer.textBox {
  margin: 126px 0 200px;
}

.titleText::after {
  top: 170px;
}

.titleText + p {
  margin-top: 84px;
}

.menu {
  text-align: center;
}

.titleText.ja::after {
  top: 113px
}

.career .textBox {
  padding: 5%;
}

.menuBox .text {
  color: #660032;
  font-family: "EB Garamond", sans-serif;
  font-weight: 400;
  font-size: 36px;
  margin: 45px 0;
}

@media (max-width: 1290px) {
  .titleText::after {
    top: 136px;
    left: 50%;
    transform: rotate(-45deg) translateX(-50px);
  }

  .imgBox img {
    height: auto;
    width: 100%;
  }

  .box, .tripleColumnsContainer {
    flex-direction: column;
    overflow: visible;
  }

  .textBox, .titleText, .bigText {
    text-align: center;
  }
}

@media (max-width: 1264px) {
  .job{
    width: 600px;
    height: 130px;
  }

  .job p{
    font-size: 22px;
  }

  button{
    font-size: 28px;
  }

}

@media (max-width: 890px) {
  .job{
    flex-direction: column;
    justify-content: center;
    width: 290px;
    height: 290px;
    padding: 0;
  }

  .job p {
    white-space: pre-line;
  }

  .cta{
    margin-top: 20px;
  }
}

@media (max-width: 640px) {
  .title{
    font-size: 38px;
  }

  .imgBox {
    text-align: center;
  }

  hr {
    width: 200px;
  }

  .titleText.ja::after{
    top: 86px;
  }

  .titleText::after {
    top: 135px;
    left: 35vw;
    transform: rotate(-45deg);
  }

  .career .textBox {
    padding: 0;
    width: 85%;
  }

  .menuBox .text {
    font-size: 28px;
  }

  .career {
    margin-top: 60px;
  }

  .career img {
    width: 100%;
  }

}

</style>