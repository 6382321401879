<template>
    <div class="language">
      <span :class="[$root._i18n._vm.locale===lang ? 'current' : '', mode]" v-for="(lang, i) in languages" :key="`Lang${i}`" :value="lang"
            v-on:click="changeTypo(lang)">{{ lang }}</span>
      <div v-if="mode==='normal'">
        <a href="https://m.facebook.com/www.restaurantkei.fr/?locale2=fr_FR" target="_blank">
          <img :src="require('../assets/images/fb.png')" class="media" alt="facebook"/>
        </a>
        <a href="https://www.instagram.com/restaurantkei/?hl=fr" target="_blank">
          <img :src="require('../assets/images/insta.png')" class="media" alt="instagram"/>
        </a>
      </div>

    </div>
   
</template>

<script>

export default {
  name: 'LanguagePicker',
  props: {
    mode: String
  },
  data() {
    return {
      languages: ['fr', 'en', 'ja'],
      active: screen.width > 640,
      screenWidth: screen.width,
      whiteMode: false,
      showBookingPage: false,
      showNews: false,
    };
  },

  mounted() {
  },
  methods: {
    changeTypo(lang) {
      this.$i18n.locale = lang;
    },
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');


.language {
  width: 28%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.media {
  width: 20px;
}

.language > *, .language div > * {
  margin: 0 4px;
  cursor: pointer;
}

.language div {
  margin: 0;
}

.language span:first-child {
  margin-left: 0;
}

.language span {
  transition: all .5s ease-in;
  color: #646470;
}

.language span:hover {
  color: #000;
}

.language span.current {
  color: #000;
  font-weight: bold;
}

.language span.alternate:hover {
  color: #fff;
}

.language span.alternate.current {
  color: #fff;
}


@media (max-width: 640px) {
  .language {
    align-items: flex-start;
  }

  .language div a:first-child {
    margin-left: 0;
  }

  .language > * {
    margin: 4px 4px;
  }
}
</style>
